<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-12">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                {{ isQuotations ? 'Devis' : 'Ventes' }}
              </span>
            </h3>
            <div v-if="!isQuotations" class="card-toolbar">
              <b-button v-if="csvLoading" variant="secondary" class="font-weight-bolder mr-3 d-flex flex-row align-items-center" disabled>
                <b-spinner small /> Télécharger en Excel
              </b-button>
              <b-button v-else @click.prevent="exportCsv" variant="secondary" class="font-weight-bolder mr-3 d-flex flex-row align-items-center">
                <i class="fas fa-file-download mr-4" /> Télécharger en Excel
              </b-button>

              <a href="#" v-if="hasUserRights([{ section: 'sales', rights: ['create'] }])" @click.prevent="newItem" class="btn btn-primary font-weight-bolder d-flex flex-row align-items-center">
                <i class="fas fa-folder-plus mr-4"/> <span>Nouvelle vente</span>
              </a>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-4">
            <!--begin::Table-->
            <vue-good-table
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isTableLoading"
              :pagination-options="{ enabled: true }"
              :sort-options="{ enabled: false, initialSortBy: serverParams.sort }"
              :rows="rows"
              :columns="columns"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-row-click="toSale"
              styleClass="custom-table clickable"
            >
              <div slot="loadingContent" class="text-center">Chargement...</div>
              <div slot="emptystate" class="text-center">Aucun élément à afficher</div>

              <template #column-filter="{ column, updateFilters }">
                <template
                  v-if="column.filterOptions && column.filterOptions.customFilter"
                >
                  <v-select
                    @input="(e) => updateFilters(column, e)"
                    @search="(search, loadingFn) => onSearchCustomFilter(column, search, loadingFn)"
                    :filterable="false"
                    :options="customFilters[column.field]"
                    :reduce="(e) => e.id"
                    label="id"
                    placeholder="Tous"
                    required
                  >
                    <template #no-options>
                      Rechercher...
                    </template>
                    <template #option="option">
                      <div class="d-center">
                        {{ column.filterOptions.displayResults(option) }}
                      </div>
                    </template>
                    <template #selected-option="option">
                      <div class="selected d-center">
                        {{ column.filterOptions.displayResults(option) }}
                      </div>
                    </template>
                  </v-select>
                </template>
              </template>

              <template slot-scope="props" slot="table-row">
                <!-- Column: Action -->
                <span v-if="props.column.field === 'action'">
                    <a @click.stop="duplicateSale(props.row.id)" v-if="hasUserRights([{ section: 'sales', rights: ['create'] }])" class="ml-3" href="#" title="Dupliquer la vente">
                        <i class="fas fa-copy text-primary"/>
                    </a>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                  <b-badge :variant="statusToColorClass(props.row.status)">
                    {{ statusToLabel(props.row.status) }}
                    <small v-if="statusToRelatedDateField(props.row.status)">
                      <br>
                      {{ dateToStr(props.row[statusToRelatedDateField(props.row.status)]) }}
                    </small>
                  </b-badge>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">Afficher </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '25', '50', '100']"
                      class="mx-1"
                      @input="
                        (value) => props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      entrées par page (Total : {{ props.total }})
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="serverParams.page"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    >
                      <template #prev-text>
                        <i class="fas fa-chevron-left"></i>
                      </template>
                      <template #next-text>
                        <i class="fas fa-chevron-right"></i>
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <!--end::Dashboard-->

    <b-modal v-model="modal.show" :title="modal.title" hide-footer no-close-on-backdrop>
     <div class="d-block">
        <b-form id="modal-form" @submit.prevent="onSubmitItem">
            <b-form-group label="Client :">
              <v-select
                v-model="modal.item.customer"
                @search="fetchCustomerOptions"
                :options="customerOptions"
                :filterable="false"
                label="id"
                placeholder="Rechercher un client (nom ou adresse email)..."
                required
              >
                <template slot="no-options">Aucun client trouvé</template>
                <template slot="option" slot-scope="option">
                    {{ option.firstname }} {{ option.lastname }} ({{ option.email }})
                </template>
                <template slot="selected-option" slot-scope="option">
                    {{ option.firstname }} {{ option.lastname }} ({{ option.email }})
                </template>
              </v-select>
            </b-form-group>
            
            <div class="text-right">
                <b-button class="mr-2" @click.prevent="hideModal" variant="light-primary">Annuler</b-button>
                <b-button type="submit" variant="primary">Créer la vente</b-button>
            </div>
        </b-form>
     </div>
   </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import SaleStatus from "@/helpers/SaleStatus";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import _ from "lodash";

export default {
  name: "sales",
  data() {
    return {
      isQuotations: false,
      columns: [
        {
          label: "Date",
          field: "createdAt",
          formatFn: this.dateToStr,
          sortable: true,
          width: "100px",
        },
        {
          label: "N°",
          field: "number",
          sortable: true,
          width: "90px",
          filterOptions: {
            enabled: true,
            placeholder: 'N°',
            fullMatch: true,
          },
        },
        {
          label: "Client",
          field: "customer",
          formatFn: (e) => e ? `${e.firstname} ${e.lastname}` : 'Client supprimé',
          filterOptions: {
            enabled: true,
            customFilter: true,
            fullMatch: true,
            filterApi: {
              endpoint: "/customers",
              params: [
                "firstname_contains",
                "lastname_contains",
                "email_contains",
              ],
            },
            displayResults: (e) => `${e.firstname} ${e.lastname}`,
          },
        },
        {
          label: "Total HT",
          field: "totalWithoutVat",
          formatFn: (e) => `${e.toFixed(2)}€`,
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: 'Total HT',
            fullMatch: true,
          },
          width: '150px',
        },
        // {
        //   label: "Remise",
        //   field: "discount",
        //   sortable: true,
        //   formatFn: (e) => e ? `${e.toFixed(2)}%` : '',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Remise',
        //     fullMatch: true,
        //   },
        //   width: '150px',
        // },
        {
          label: "Total TTC",
          field: "totalWithVat",
          formatFn: (e) => `${e.toFixed(2)}€`,
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: 'Total TTC',
            fullMatch: true,
          },
          width: '150px',
        },
        {
          label: "Statut",
          field: "status",
          formatFn: this.statusToStr,
          sortable: true,
          width: "90px",
          filterOptions: {
            enabled: true,
            placeholder: 'Tous',
            fullMatch: true,
            filter: true,
            filterDropdownItems: Object.keys(SaleStatus).map(e => ({
              value: e, text: SaleStatus[e].label
            }))
          },
        },
        {
          label: "Décaissé",
          field: "destocked",
          formatFn: (e) => e ? this.dateToStr(e) : "Non"
        },
        // "reference": "1234",
        // "name": "Service name",
        // "price": 99,
        // "showTotalWithVat": true,
        // "discount": 0,
        // "doneAt": null,
        // "destocked": false,
        // "status": "PENDING_APPROVAL",
        // "isCanceled": false,

        // "customer": null,
        // "productItems": []
        // { label: "", field: "action", width: "80px" },
      ],
      rows: [],
      recents: [],
      customFilters: [],
      totalRecords: 0,
      isTableLoading: true,
      csvLoading: false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "number",
          type: "desc",
        },
        page: 1,
        perPage: 10,
      },
      modal: {
          show: false,
          title: "Nouvelle vente",
          item: {
            id: null,
            reference: null,
            name: null,
            price: null,
            showTotalWithVat: true,
            discount: null,
            doneAt: null,
            destocked: null,
            status: "PENDING",
            canceled: null,
            customer: null,
          },
      },
      customerOptions: [],
    };
  },
  watch: {
    // call again the method if the route changes
    '$route': 'init'
  },
  computed: {
    ...mapGetters(["hasUserRights"]),
  },
  mounted() {
    this.init();
  },
  methods: {
    statusToColorClass(status) {
      try {
        return SaleStatus[status].colorClass;
      } catch (err) {
        console.warn(err);
        return "";
      }
    },
    statusToLabel(status) {
      try {
        return SaleStatus[status].label;
      } catch (err) {
        console.warn(err);
        return "N/C";
      }
    },
    statusToRelatedDateField(status) {
      try {
        return SaleStatus[status].relatedDateField;
      } catch (err) {
        console.warn(err);
        return null;
      }
    },
    toSale(props) {
      this.$router.push({ name: props.row.isQuotation ? 'quotation' : 'sale', params: { id: props.row.id } });
    },
    init() {
      this.isQuotations = this.$route.name === 'quotations';
      if (this.isQuotations) {
        this.columns = this.columns.filter(e => e.label != "");
        this.columns.splice(2, 0, {
          label: "PEC",
          field: "treatment",
          formatFn: e => e.number,
          sortable: false,
          filterOptions: {
            enabled: true,
            customFilter: true,
            fullMatch: true,
            filterApi: { endpoint: '/treatments', params: ['number'], populate: 'deviceModel' },
            displayResults: (e) => `${e.number} (${e.deviceModel.name})`,
          }
        });
      } else {
        this.columns = this.columns.filter(e => e.label != 'PEC');
        this.columns.push({ label: "", field: "action", width: "80px" });
      }
      this.$store.dispatch(SET_BREADCRUMB, [{ title: this.isQuotations ? 'Devis' : 'Ventes' }]);
      this.fetchItemsFromApi();
    },
    statusToStr(status) {
      return SaleStatus[status] || 'N/C';
    },
    fetchCustomerOptions(search, loading) {
      if (!search.length) return;
      loading(true);
      ApiService
        .query('/customers', { params: { firstname_contains: search, lastname_contains: search, email_contains: search, _or: 1 } })
        .then(({ data }) => {
            this.customerOptions = data.results;
            loading(false);
        })
        .catch(() => { loading(false); });
    },
    hideModal() {
        this.modal.show = false;
    },
    newItem() {
        this.modal.item = { id: null, reference: null, name: null, price: null, showTotalWithVat: true, discount: null, doneAt: null, destocked: null, status: "PENDING", canceled: null, customer: null };
        this.modal.title = this.isQuotations ? 'Nouveau devis' : 'Nouvelle vente';
        this.modal.show = true;
    },
    onSubmitItem() {
        this.modal.item.customer = this.modal.item.customer.id;
        this.modal.item.isQuotation = false;

        ApiService
            .post(`/sales`, this.modal.item)
            .then(({ data }) => {
                this.$router.push({ name: 'sale', params: { id: data.id } });
                this.modal.show = false;
            });
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.fetchItemsFromApi();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.onPageChange({ currentPage: 1 });
    },
    onSortChange([params]) {
      this.updateParams({
        sort: {
          field: params.field,
          type: params.type,
        },
      });
      this.onPageChange({ currentPage: 1 });
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.onPageChange({ currentPage: 1 });
    },
    getApiParams() {
      const tableSearch = {};
      Object.keys(this.serverParams.columnFilters).forEach((e) => {
        if (!this.serverParams.columnFilters[e]) return;

        if (this.columns.find(column => column.field == e)?.filterOptions?.fullMatch) {
          tableSearch[e] = this.serverParams.columnFilters[e];
        } else {
          tableSearch[`${e}_contains`] = this.serverParams.columnFilters[e];
        }
      });

      return {
        ...tableSearch,
        isQuotation: this.isQuotations,
        limit: this.serverParams.perPage,
        page: this.serverParams.page,
        sortBy: `${this.serverParams.sort.field}:${this.serverParams.sort.type}`,
        populate: 'customer treatment',
      };
    },
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isTableLoading = true;
      ApiService
        .query("/sales", { params: this.getApiParams() })
        .then((res) => {
          this.rows = res.data.results;
          this.totalRecords = res.data.totalResults;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },
    /**
     * Export table to CSV file
     */
    exportCsv() {
      this.csvLoading = true;
      ApiService
        .query("/sales", { params: { ...this.getApiParams(), toCsv: 1 } })
        .then(({ data }) => {
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
          anchor.target = '_blank';
          anchor.download = 'ventes.csv';
          anchor.click();
          this.csvLoading = false;
        })
        .catch(() => {
          this.csvLoading = false;
        });
    },

    // CUSTOM FILTERS
    /**
     * Triggered when a search is performed on a custom filter in the table
     */
    onSearchCustomFilter(column, search, loading) {
      if (search.length) {
        loading(true);
        this.debounceCustomFilter(loading, column, search, this);
      }
    },
    /**
     * Perform custom filter via API (debounced)
     */
    debounceCustomFilter: _.debounce((loading, column, search, vm) => {
      const params = { _or: 1 };
      column.filterOptions.filterApi.params.forEach(e => {
        params[e] = search;
      });
      params.populate = column.filterOptions.filterApi.populate;

      ApiService.query(column.filterOptions.filterApi.endpoint, { params })
        .then((res) => {
          vm.customFilters[column.field] = res.data.results;
          loading(false);
        })
        .catch(() => {
          vm.customFilters[column.field] = [];
          loading(false);
        });
    }, 350),

    /**
     * Duplicate a sale based on an existing sale's ID
     */
    duplicateSale(id) {

      this.$swal.fire({
          title: 'Êtes-vous sûr de vouloir dupliquer cette vente ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmer',
      }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            ApiService
              .get('/sales', id)
              .then(({ data }) => {
                  const saleData = { ...data };
                  saleData.customer = saleData.customer.id;
                  saleData.status = "PENDING";

                  delete saleData.products;
                  delete saleData.invoice;
                  delete saleData.createdAt;
                  delete saleData.updatedAt;

                  ApiService
                      .post(`/sales`, saleData)
                      .then(({ data: newSale }) => {
                        Promise.all(
                          data.products.map(productItem => {
                            new Promise((resolve, reject) => {
                              productItem.product = productItem.product.id;
                              productItem.attachedToKind = 'Sale';
                              productItem.attachedToDocument = newSale.id;
                              ApiService
                                  .post('/product-items', productItem)
                                  .then(({ data }) => {
                                    resolve(data);
                                  })
                                  .catch(err => {
                                      reject(err);
                                  });
                            });
                          })
                        ).then(() => {
                          this.$router.push({ name: 'sale', params: { id: newSale.id } });
                          // this.fetchItemsFromApi();
                        })
                      });
              })
              .catch(() => {
                  alert('Vente introuvable');
              });
          }
      });
    },
  },
};
</script>